<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New Notification
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-bell-alert-outline" prominent type="info">
          <div>
            <h3>Custom Notifications with Search Filters</h3>
            <p>Notifications in UnStruct are powered by search filters, allowing for precise control over who gets notified and when. These filters enable you to specify conditions that must be met for notifications to be triggered, ensuring that the right people are alerted at the right time.</p>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
              <v-card outlined class="ma-3 elevation-2">

                <!-- Notification Name and Status -->
                <v-card-title>
                  {{ item.name }}
                  <v-spacer></v-spacer>
                  <v-chip :color="item.enabled ? 'success' : 'grey'" dark>
                    <v-icon left>{{ item.enabled ? 'mdi-lightbulb-on-outline' : 'mdi-lightbulb-off-outline' }}</v-icon>
                    {{ item.enabled ? 'Enabled' : 'Disabled' }}
                  </v-chip>
                </v-card-title>

                <!-- Notification Type, Target, and Evergreen Status -->
                <v-card-subtitle class="px-4 py-4">
                  <v-chip :color="getTypeColor(item.type)" dark class="mr-2">
                    <v-icon left>{{ getTypeIcon(item.type) }}</v-icon>
                    {{ item.type.charAt(0).toUpperCase() + item.type.slice(1) }}
                  </v-chip>
                  <v-chip color="secondary" dark class="mr-2">
                    <v-icon left>mdi-target</v-icon>
                    {{ item.target }}
                  </v-chip>
                  <v-chip :color="item.evergreen ? 'success' : 'red'" dark class="mr-2">
                    <v-icon left>{{ item.evergreen ? 'mdi-autorenew' : 'mdi-pause-circle-outline' }}</v-icon>
                    {{ item.evergreen ? 'Evergreen' : 'Not Evergreen' }}
                  </v-chip>
                </v-card-subtitle>

                <!-- Notification Filters -->
                <v-card-text class="px-4 py-3">
                  <div class="mr-2 mb-2">
                    <v-chip color="success" dark>
                      <v-icon left>mdi-filter</v-icon>
                      Filters: {{ item.filters.length }}
                    </v-chip>
                  </div>
                  <div v-for="filter in item.filters" :key="filter.id" class="mb-2">
                    <search-filter :filter="filter" />
                  </div>
                </v-card-text>

                <!-- Actions -->
                <v-card-actions class="justify-end">
                  <v-btn color="primary" text @click="createEditShow(item)">Edit</v-btn>
                  <v-btn color="error" text @click="removeShow(item)">Delete</v-btn>
                </v-card-actions>

              </v-card>
            </v-col>
          </v-row>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/notification/DeleteDialog.vue"
import NewEditSheet from "@/notification/NewEditSheet.vue"
import SearchFilter from "@/search/SearchFilter.vue"

export default {
  name: "NotificationTable",

  components: {
    DeleteDialog,
    NewEditSheet,
    SearchFilter,
    SettingsBreadcrumbs,
  },

  computed: {
    ...mapFields("notification", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("notification", ["getAll", "createEditShow", "removeShow"]),
    getTypeColor(type) {
      switch (type) {
        case 'email': return 'cyan';
        case 'conversation': return 'lime';
        default: return 'grey';
      }
    },
    getTypeIcon(type) {
      switch (type) {
        case 'email': return 'mdi-email';
        case 'conversation': return 'mdi-slack';
        default: return 'mdi-axis-arrow-info';
      }
    },
  },
}
</script>

<style scoped>
.chip-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
</style>
